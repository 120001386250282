(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var manageFieldsAPI = function manageFieldsAPI(value, type, action) {
    $.ajax({
        type: "POST",
        url: "/api/manage-fields.php",
        data: {
            "value": value,
            "type": type,
            "action": action
        },
        success: function success(data) {},
        error: function error(err) {
            return console.log("Error: ", new Error(err));
        }
    });
};

function manageFields(action, type) {
    var _this = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

    var value = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

    var isEmail = type == 'email' ? true : false;

    var id = isEmail ? $('#emails').find('[data-id]').length + 1 : $('#phones').find('[data-id]').length + 1;

    var fieldTemplate = "\n    <div id=\"" + (isEmail ? 'email' : 'phone') + "-" + id + "\" data-id=\"" + id + "\">\n        <input name=\"" + (isEmail ? 'email' : 'phone') + "\" value=\"" + value + "\" type=\"text\" placeholder=\"" + (isEmail ? 'Email' : 'Телефон') + ":\"/>\n        " + (id != 1 ? '<span data-type="' + type + '" class="delete-btn"></span>' : '') + "\n    </div>\n    ";

    if (action == 'add' || action == 'create') {
        isEmail ? $('#emails').append(fieldTemplate) : $('#phones').append(fieldTemplate);

        if (action != 'create') {
            manageFieldsAPI(id, type, action);
        }
    } else if (action == 'delete') {
        var typeToDelete = $(_this).data('type');
        var idToDelete = $(_this).parent().data('id');

        $("#" + typeToDelete + "-" + idToDelete).remove();
        manageFieldsAPI($(_this).parent().find('input')[0].value, typeToDelete, 'delete');
    }
}

var getLandingStatus = function getLandingStatus() {
    $.ajax({
        type: "POST",
        url: "/api/get-status.php",
        success: function success(data) {
            var propOn = !!parseInt(JSON.parse(data)[0].enabled);
            var toCheck = propOn ? $('#langing-status').find('.radio-conteiner')[0] : $('#langing-status').find('.radio-conteiner')[1];
            $(toCheck).find('input').attr('checked', 'checked').parent().addClass('checked');
            $('#langing-status').attr('data-status', "" + (propOn ? true : false));

            if ($('.proposition-on') || $('.proposition-off')) {
                if (propOn) {
                    $('.proposition-off').remove();
                    $('.proposition-on').addClass('visible');
                } else {
                    $('.proposition-on').remove();
                    $('.proposition-off').addClass('visible');
                }
            }
        }
    });
};

var getBannerStatus = function getBannerStatus() {
    $.ajax({
        type: "POST",
        url: "/api/get-banner-status.php",
        success: function success(data) {
            var propOn = !!parseInt(JSON.parse(data)[0].enabled);
            var toCheck = propOn ? $('#banner-status').find('.radio-conteiner')[0] : $('#banner-status').find('.radio-conteiner')[1];
            $(toCheck).find('input').attr('checked', 'checked').parent().addClass('checked');
            $('#banner-status').attr('data-status', "" + (propOn ? true : false));

            if ($('.proposition-on') || $('.proposition-off')) {
                if (propOn) {
                    $('.proposition-off').remove();
                    $('.proposition-on').addClass('visible');
                } else {
                    $('.proposition-on').remove();
                    $('.proposition-off').addClass('visible');
                }
            }
        }
    });
};

var getCards = function getCards() {
    $.ajax({
        type: "POST",
        url: "/api/get-cards.php",
        data: {},
        success: function success(data) {
            JSON.parse(data).forEach(function (el) {
                $("#card-" + el.id + " .card-price")[0].value = el.price;
                $("#card-" + el.id + " .card-date-from")[0].value = el.date_from;
                $("#card-" + el.id + " .card-year-from")[0].value = el.year_from;
                $("#card-" + el.id + " .card-date-to")[0].value = el.date_to;
                $("#card-" + el.id + " .card-year-to")[0].value = el.year_to;
                $("#card-" + el.id + " .card-places-all")[0].value = el.places_all;
                $("#card-" + el.id + " .card-places-busy")[0].value = el.places_busy;
            });
        }
    });
};
var getContacts = function getContacts() {
    $.ajax({
        type: "POST",
        url: "/api/get-contacts.php",
        data: {},
        success: function success(data) {
            JSON.parse(data).forEach(function (el) {
                return manageFields('create', el.type, null, el.value);
            });
        }
    });
};

var saveData = function saveData() {
    $.ajax({
        type: "POST",
        url: "/api/get-contacts.php",
        data: collectData(),
        success: function success(data) {
            JSON.parse(data).forEach(function (el) {
                return manageFields('create', el.type, null, el.value);
            });
        }
    });
};

$(document).ready(function () {
    getLandingStatus();
    getCards();
    getContacts();
});

$('#add-email').click(function () {
    manageFields('add', 'email');
});
$('#add-phone').click(function () {
    manageFields('add', 'phone');
});
$('#emails').click(function (e) {
    if ($(e.target).hasClass('delete-btn')) {
        manageFields('delete', 'any', e.target);
    }
});
$('#phones').click(function (e) {
    if ($(e.target).hasClass('delete-btn')) {
        manageFields('delete', 'any', e.target);
    }
});

$('#langing-status .radio-conteiner').click(function () {
    if (!$(this).hasClass('checked')) {
        $('#langing-status').find('.checked').removeClass('checked');
        $(this).addClass('checked');
        $('#langing-status').attr('data-status', $('#langing-status').find('.checked').data('val'));
    }
});

// SAVE DATA

$('[data-action="save"]').click(function () {
    var landingStatus = $('#langing-status').data('status');

    var cardsData = [];
    var cards = ['#card-1', '#card-2', '#card-3'];
    cards.forEach(function (el) {
        var data = {
            id: el.replace('#card-', ''),
            price: $(el).find('input.card-price')[0].value,
            dateFrom: $(el).find('input.card-date-from')[0].value,
            yearFrom: $(el).find('input.card-year-from')[0].value,
            dateTo: $(el).find('input.card-date-to')[0].value,
            yearTo: $(el).find('input.card-year-to')[0].value,
            placesAll: $(el).find('input.card-places-all')[0].value,
            placesBusy: $(el).find('input.card-places-busy')[0].value
        };
        cardsData.push(data);
    });

    var phones = [].concat(_toConsumableArray($('#phones').find('input'))).map(function (el) {
        return { val: el.value, "type": "phone" };
    });
    var emails = [].concat(_toConsumableArray($('#emails').find('input'))).map(function (el) {
        return { val: el.value, "type": "email" };
    });

    var alldata = {
        'status': landingStatus,
        'cardsData': cardsData,
        'contacts': [].concat(_toConsumableArray(emails), _toConsumableArray(phones))
    };

    $.ajax({
        type: 'POST',
        url: '/api/save-data.php',
        data: alldata,
        success: function success() {
            console.log("Success!");
            location.reload(false);
        },
        error: function error(err) {
            return console.log("Error: ", new Error(err));
        }
    });
});

},{}],2:[function(require,module,exports){
'use strict';

require('./admin.js');

function getDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    return '' + yyyy + (mm < 10 ? '0' + mm : mm) + (dd < 10 ? '0' + dd : dd);
}

var errorMsg = function errorMsg(msg) {
    return '<span style="color: #ff0000; font-size:12px>' + msg + '</span>';
};

function showSbmMsg() {
    $('#message-sbmt').addClass('opened');
    setTimeout(function () {
        $('#message-sbmt').removeClass('opened');
    }, 3000);
}

var getCards = function getCards() {
    $.ajax({
        type: "POST",
        url: "/api/get-cards.php",
        data: {},
        success: function success(data) {
            JSON.parse(data).forEach(function (el) {
                el.date_to = el.date_to.length != 5 ? '0' + el.date_to : el.date_to; // if date is of 1 symb adds zero
                el.date_from = el.date_from.length != 5 ? '0' + el.date_from : el.date_from; // if date is of 1 symb adds zero

                // date from 15.10.2010 to 20101015
                var dateTO = el.year_to + el.date_to.replace('.', '')[2] + el.date_to.replace('.', '')[3] + el.date_to.replace('.', '')[0] + el.date_to.replace('.', '')[1];
                var dateFROM = el.year_from + el.date_from.replace('.', '')[2] + el.date_from.replace('.', '')[3] + el.date_from.replace('.', '')[0] + el.date_from.replace('.', '')[1];
                if ($('#card-' + el.id + ' [data-price]').length) {
                    if (parseInt(dateTO) - parseInt(getDate()) < 0 || parseInt(dateFROM) - parseInt(getDate()) > 0) {
                        $('#card-' + el.id).addClass('closed');
                    }

                    $('#card-' + el.id + ' [data-price]')[0].innerHTML = '€' + el.price;
                    $('#card-' + el.id + ' [data-till]')[0].innerHTML = '\u0417\u0430\u043F\u0438\u0441\u044C \u0434\u043E ' + (el.date_to + '.' + el.year_to);
                    $('#card-' + el.id + ' [data-people]')[0].innerHTML = '\u0417\u0430\u043F\u0438\u0441\u0430\u043B\u043E\u0441\u044C \u0447\u0435\u043B\u043E\u0432\u0435\u043A: ' + el.places_busy;
                    $('#card-' + el.id + ' [data-vacant]')[0].innerHTML = '\u041E\u0441\u0442\u0430\u043B\u043E\u0441\u044C \u043C\u0435\u0441\u0442: ' + (el.places_all - el.places_busy);
                }
            });
        }
    });
};

$(document).ready(function () {

    $('[name="phone"]').mask("+38(999)999-99-99");

    $('.slider').slick({
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        arrows: true,
        prevArrow: $('#slider-prev'),
        nextArrow: $('#slider-next'),
        responsive: [{
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        }, {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '20px',
                slidesToShow: 1
            }
        }]
    });

    // Collapse navigation
    $('[data-action="open-nav"]').click(function () {
        $('#nav').addClass('collapsed');
        $('#nav li').addClass('animated');
    });
    $('[data-action="close-nav"]').click(function () {
        $('#nav').removeClass('collapsed');
        $('#nav li').removeClass('animated');
    });

    // Show popup
    $('[data-action="popup"]').click(function (e) {
        e.preventDefault();
        $('#overlay').addClass('opened');
        $('#popup').addClass('opened');

        var monthes = ['На июнь 2019', 'На июль 2019', 'На август 2019'];
        if ($(this).data('month')) {
            $('#popup').find('.subtitle')[0].innerHTML = monthes[$(this).data('month')];
        } else {
            $('#popup').find('.subtitle')[0].innerHTML = '';
        }
    });
    $('#overlay').click(function () {
        $('#overlay').removeClass('opened');
        $('#popup').removeClass('opened');
    });
    $('#popup .close').click(function () {
        $('#overlay').removeClass('opened');
        $('#popup').removeClass('opened');
    });
    $('[data-action="submit"]').click(function () {
        var name = $('#popup [name="name"]')[0].value;
        var phone = $('#popup [name="phone"]')[0].value;
        var date = $('#popup .subtitle')[0].innerHTML;
        if (!phone || !name) {
            $('#popup .err').addClass('show');
            $('#popup .err')[0].innerHTML = 'Поля обязательны для заполнения';
        } else {
            $('#popup .err').removeClass('show');
            $('#popup .err')[0].innerHTML = '';
            $.ajax({
                type: "POST",
                url: "/api/send-mail.php",
                data: {
                    "name": name,
                    "date": '' + (date != '' ? date : "Запись с баннера"),
                    "phone": phone
                }
            });
            $('#overlay').removeClass('opened');
            $('#popup').removeClass('opened');
            $('#popup [name="name"]')[0].value = '';
            $('#popup [name="phone"]')[0].value = '';
            setTimeout(function () {
                showSbmMsg();
            }, 500);
        }
    });

    $('[data-action="send-phone"]').click(function () {
        var phone = $('#callback-form input')[0].value;
        if (!phone) {
            $('#callback-form .err').addClass('show');
            $('#callback-form .err')[0].innerHTML = 'Заполните поле';
        } else {

            $.ajax({
                type: "POST",
                url: "/api/send-mail.php",
                data: {
                    "phone": phone
                },
                success: function success() {
                    console.log('Sent!');
                }
            });
            $('#callback-form .err').removeClass('show');
            $('#callback-form input')[0].value = '';
            $('#callback-form .err')[0].innerHTML = '';
            setTimeout(function () {
                showSbmMsg();
            }, 500);
        }
    });

    getCards();
});

},{"./admin.js":1}]},{},[2]);
